import { ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import {
  AthleteState,
  EventState,
  NewCompetitionState,
  ResultState,
  SharedApiService,
  SharedDataService,
  SharedDataState,
} from '@beathletics/beathletics-data-state';
import { NgxsModuleOptions, provideStates, provideStore } from '@ngxs/store';
import { routes } from './app.routes';

import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS, provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FrontAuthModule, KEYCLOAK_URL } from '@beathletics/auth';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { RouterStateSerializer, withNgxsRouterPlugin } from '@ngxs/router-plugin';
import { fr } from 'date-fns/locale';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { environment } from '../environments/environment';
import { CustomRouterStateSerializer } from './custom-router-serializer';
import { EnvService } from '@beathletics/auth';
import { ThemeColorService } from './shared/theme-color.service';
import { TranslocoHttpLoader } from './transloco-root.module';

registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeNl, 'nl', localeNlExtra);

export const ngxsConfig: NgxsModuleOptions = {
  developmentMode: !environment.production,
  selectorOptions: {
    suppressErrors: false,
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    provideStore(
      [NewCompetitionState, AthleteState, EventState, ResultState, SharedDataState],
      ngxsConfig,
      withNgxsReduxDevtoolsPlugin({ disabled: environment.production }),
    ),
    ...environment.devToolsModules,
    withNgxsRouterPlugin(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    EnvService,
    provideTransloco({
      config: {
        availableLangs: ['en', 'fr', 'nl', 'de'],
        defaultLang: 'fr',
        flatten: { aot: true },
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,

    }),

    provideTranslocoMessageformat(),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
    ),
    provideStates([]),
    importProvidersFrom(FrontAuthModule),
    {
      provide: KEYCLOAK_URL,
      useFactory: (envService: EnvService) => envService.getEnv('KEYCLOAK_URL', '/auth'),
      deps: [EnvService],
    },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    ThemeColorService,
    { provide: LOCALE_ID, useValue: 'fr-BE' },
    SharedDataService,
    SharedApiService,

    provideShareButtonsOptions(shareIcons()),

    { provide: MAT_DATE_LOCALE, useValue: fr },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    provideDateFnsAdapter(),
  ],
};
