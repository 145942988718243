import { Component, inject, OnInit } from '@angular/core';
import { AuthService, FrontAuthModule } from '@beathletics/auth';
import { MetaService } from './shared/meta.service';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { RouterNavigation } from '@ngxs/router-plugin';
import { provideRouter, RouterModule, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { EnvService } from '../../../../libs/front-auth/src/lib/env.service';

@Component({
  selector: 'beathletics-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule, FrontAuthModule],
})
export class AppComponent implements OnInit {
  #actions$ = inject(Actions);
  #authService = inject(AuthService);
  #metaService = inject(MetaService);
  #transloco = inject(TranslocoService);
  #envService = inject(EnvService);

  profile = this.#authService.getProfile();
  roles = this.#authService.getRoles();

  logout() {
    this.#authService.logout();
  }

  ngOnInit() {
    this.#transloco.selectTranslation().subscribe();
    const disableRobots = this.#envService.getEnv('DISABLE_ROBOTS', 'false');
    this.#metaService.disableRobots(disableRobots);
    this.#actions$.pipe(ofActionSuccessful(RouterNavigation)).subscribe(() => this.#metaService.setBasicMetaTags());
  }
}
